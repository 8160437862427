import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #ff3314;
  color: #ff3314;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 6px 0px;
`

const Medal: React.FC<{ rank: string; className?: string }> = ({
  rank,
  className,
}) => {
  return <Container className={className}>{rank}</Container>
}

export default Medal
