import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import DarkModeSwitch from "./DarkModeSwitch"

const Container = styled.footer`
  width: 100%;
  padding: 0 15px 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 0.78em;
  }
  font-weight: 400;
  color: var(--gray-2);

  a {
    color: inherit;
  }
`

const Footer: React.FC = () => {
  return (
    <Container>
      <span>Copyright 2021 © </span>
      <Link to="/">LivePoll.io</Link>
      <span> - </span>
      <Link to="/about">About</Link>
      <span> - </span>
      <DarkModeSwitch />
    </Container>
  )
}

export default Footer
