import React from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import "bootstrap/dist/css/bootstrap-reboot.min.css"
import "bootstrap/dist/css/bootstrap-utilities.min.css"
import "./App.css"
import Footer from "./components/Footer"
import Header from "./components/Header"
import Main from "./components/UI/Main"
import RootAbout from "./scenes/about"
import RootCreation from "./scenes/pollCreation"
import RootResults from "./scenes/pollResults"
import RootVote from "./scenes/pollVote"
import theme from "./utils/theme"
import WithTracker from "./utils/WithTracker"

const Router: React.FC = () => {
  return (
    <React.Fragment>
      <Main>
        <Header />
        <Switch>
          <Route component={RootCreation} exact path="/" />
          <Route component={RootAbout} exact path="/about" />
          <Route component={RootResults} path="/:id/results" />
          <Route component={RootVote} path="/:id" />
          <Redirect to="/" />
        </Switch>
      </Main>
      <Footer />
    </React.Fragment>
  )
}

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Route component={WithTracker(Router)} />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
