import React from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import logo from "./logo.png"

const TextTitle = styled.h1`
  font-size: 12px;
  color: #fff;
  margin: 0;
  font-weight: 300;
  @media (max-width: 700px) {
    display: none;
  }
`

const Container = styled.div`
  background: linear-gradient(
    90deg,
    var(--primary) 0%,
    var(--primary-bis) 100%
  );
  display: flex;
  color: white;
  padding: 10px 10px 10px 20px;
  margin-bottom: 30px;
  @media (max-width: 700px) {
    font-size: 45px;
    margin-bottom: 0;
    border-radius: 0px;
    padding: 10px;
    background: var(--primary);
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
`
const LivepollLeftTitle = styled(Link)`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  display: flex;
  text-decoration: none;
  > img {
    width: 200px;
    object-fit: contain;
    min-height: 47px;
  }
  @media (max-width: 700px) {
    > img {
      width: 170px;
      max-width: 100%;
      min-height: 42px;
    }
  }
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`

const SocialDiv = styled.div`
  display: flex;
  font-size: 30px;
  @media (max-width: 700px) {
    font-size: 45px;
  }
  line-height: 0;
  align-items: flex-end;
`

const CreatePool = styled(Link)`
  text-decoration: none;
  height: 0.87em;
  background: #fff;
  border: 0;
  border-radius: 3px;
  color: #770837;
  padding: 0 10px;
  display: flex;
  align-items: center;
  span {
    font-size: 15px;
  }
`

const Header: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <Container className="md-shadow-sm">
      <LivepollLeftTitle to="/">
        <img src={logo} alt="Livepoll logo" style={{ padding: "6px 0 3px" }} />
        <TextTitle>
          Polls with Ranking voting, Expiration, IP checking
        </TextTitle>
      </LivepollLeftTitle>
      <div className="right">
        <SocialDiv>
          {pathname !== "/" && (
            <CreatePool to="/">
              <span>Create Poll</span>
            </CreatePool>
          )}
        </SocialDiv>
      </div>
    </Container>
  )
}

export default Header
