import styled from "styled-components"

const Container = styled.div`
  @media (min-width: 701px) {
    background: var(--white);
  }
  margin-bottom: 30px;
  padding: 25px;
  //min-height: 400px;
  @media (max-width: 700px) {
    padding: 15px;
    border-radius: 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`

const Block: React.FC<{ className?: string }> = ({ children, className }) => {
  return <Container className={"md-shadow-sm " + className}>{children}</Container>
}

export default Block
