import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Informations from "../../components/Informations"
import Block from "../../components/UI/Block"
import { CreationStoreProvider } from "./CreationStore"
import PollOptions from "./Options"
import AnswersList from "./Poll/AnswersList"
import Question from "./Poll/Question"
import PostPoll from "./PostPoll"

export default function RootCreation() {
  const [
    firstAnswerInput,
    setFirstAnswerInput,
  ] = useState<HTMLInputElement | null>(null)
  return (
    <CreationStoreProvider>
      <React.Fragment>
        <Helmet>
          <title>
            LivePoll - Polls with Ranking voting, Expiration, IP checking
          </title>
        </Helmet>
        <Block>
          <Question firstAnswer={firstAnswerInput} />
          <AnswersList setFirstAnswerInput={setFirstAnswerInput} />
          <PostPoll />
          <PollOptions />
        </Block>
        <Informations />
      </React.Fragment>
    </CreationStoreProvider>
  )
}
