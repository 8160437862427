import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import calendar from "dayjs/plugin/calendar"
import relativeTime from "dayjs/plugin/relativeTime"
import dayjs from "dayjs"
dayjs.extend(calendar)
dayjs.extend(relativeTime)

ReactDOM.render(<App />, document.getElementById("root"))
// registerServiceWorker()
