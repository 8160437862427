import { CheckCircle } from "@styled-icons/fa-solid"
import React, { useContext, useState } from "react"
import { RouteComponentProps } from "react-router"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import Button from "../../components/UI/Button"
import { createPollMutation } from "../../store/createPollMutation"
import { CreationContext } from "./CreationStore"
import { idToBase64 } from "../../utils/base64"
import ErrorMessage from "../pollVote/ErrorMessage"

const Container = styled.div`
  text-align: center;
  margin-bottom: 10px;
  button {
    &:focus {
      outline: none;
    }
  }
`

const PostPoll: React.FC<RouteComponentProps> = ({ history }) => {
  const [store] = useContext(CreationContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  return (
    <Container>
      <Button
        text="Create Poll"
        loading={loading}
        Icon={CheckCircle}
        onClick={() => {
          setLoading(true)
          createPollMutation(store)
            .then((id) => {
              const b64Id = idToBase64(id)
              setLoading(false)
              history.push(`/${b64Id}`)
            })
            .catch((error: string) => {
              setError(error)
              setLoading(false)
            })
        }}
      />
      <ErrorMessage error={error} />
    </Container>
  )
}

export default withRouter(PostPoll)
