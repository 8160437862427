import React from "react"
import styled from "styled-components"

const Container = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  color: #c53030;
  &::first-letter {
    text-transform: uppercase;
  }
`

const ErrorMessage: React.FC<{ error?: string }> = ({ error }) => {
  return error != null ? <Container> {error}</Container> : null
}

export default ErrorMessage
