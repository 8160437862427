import { StyledIcon } from "@styled-icons/styled-icon"
import React from "react"
import styled from "styled-components"
import { Spinner } from "@styled-icons/fa-solid"

const StyledButton = styled.button<{ secondary: boolean | undefined }>`
  display: inline-flex;
  border-radius: 6px;
  font-weight: 600;
  color: #fff;
  background: ${(p) => (p.secondary ? "#ADADAD" : "var(--primary)")};
  &:hover {
    background: ${(p) =>
      p.secondary
        ? "#a6a6a6"
        : "linear-gradient(0deg,hsl(342deg 98% 47%) 0%,hsl(8deg 100% 49%) 100%)"};
  }
  border: 0;
  padding: 16px 30px;
  line-height: 1;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  cursor: pointer;
  margin: 15px 5px 0;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  > div {
    display: flex;
  }
  small {
    margin-top: 2px;
    font-size: 0.7em;
    font-weight: 300;
  }
`

const Button: React.FC<{
  onClick?: () => void
  Icon: StyledIcon
  loading?: boolean
  secondary?: boolean
  right?: boolean
  text: string
  subText?: string
}> = ({ onClick, Icon, loading, text, secondary, right, subText }) => {
  const style = right
    ? {
        marginRight: "10px",
      }
    : {
        marginLeft: "10px",
      }
  return (
    <StyledButton onClick={onClick} secondary={secondary}>
      <div>
        {!right && text}
        {loading ? (
          <Spinner width="1em" height="1em" className="spin" style={style} />
        ) : (
          <Icon width="1em" height="1em" style={style} />
        )}
        {right && text}
      </div>
      {subText && <small>{subText}</small>}
    </StyledButton>
  )
}

export default Button
