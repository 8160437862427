import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
  text-align: justify;
  margin-top: 15px;
  color: var(--black);
  opacity: 0.7;
  padding: 0 50px 40px;
  @media (max-width: 700px) {
    padding: 0 10px;
  }
  a {
    color: inherit;
  }
`

const H2 = styled.h2`
  font-size: 23px;
  margin-top: 1.5em;
`

const Informations: React.FC = () => {
  return (
    <React.Fragment>
      <Container>
        <H2>What is LivePoll ?</H2>
        <p>
          LivePoll is an app that allows you to create polls quickly and easily.
          Several options are available including a{" "}
          <strong>ranked voting</strong> system, the{" "}
          <strong>verification of votes by IP address</strong> but also the
          possibility of setting an <strong>expiration date</strong> or hiding
          the results before a given date.
        </p>
        <H2>Ranked voting polls</H2>
        <p>
          Livepoll specialize in ranked voting. In this mode, each voter ranks
          the different options in order of preference to assign a ranking to
          each option.
        </p>
        <H2>Customization</H2>
        <p>
          We wanted to provide several options to customize the polls (and more
          are coming!). It is possible to set an expiration date for a survey
          but also to hide the results before a given date.
        </p>
        <p>
          We are always looking for ideas to improve the site. If you have any,
          please let us know via the <Link to="/about">contact page</Link>.
        </p>
        <H2>Security</H2>
        <p>
          Security and spam prevention is one of our main concerns. We offer
          several levels of security when creating surveys to ensure the
          integrity of the results. The first, by default, is IP address
          security. It is also possible to secure the survey via voters cookies.
        </p>
        <H2>Privacy and data protection</H2>
        <p>
          We understand that data protection is very important to our users.
          Therefore, IP addresses are never stored plained text but hashed in
          the database.
        </p>
      </Container>
    </React.Fragment>
  )
}

export default Informations
