import React, { useContext, useRef } from "react"
import styled from "styled-components"
import { CreationContext } from "../CreationStore"
import Answer from "./Answer"

const Container = styled.div`
  max-width: 850px;
  margin: 0 auto;
`

const AnswersList: React.FC<{
  setFirstAnswerInput: (e: HTMLInputElement) => void
}> = ({ setFirstAnswerInput }) => {
  const [store, dispatch] = useContext(CreationContext)
  const refs = useRef<(HTMLInputElement | null)[]>([])

  const last = store.choices.length - 1
  return (
    <Container>
      {store.choices.map(
        (v, i) =>
          v !== undefined && (
            <Answer
              innerRef={(r: HTMLInputElement) => {
                refs.current[i] = r
                if (i === 0) setFirstAnswerInput(r)
              }}
              handleEnter={(event) => {
                if (event.key === "Enter") {
                  refs.current[i + 1]?.focus()
                  event.preventDefault()
                }
              }}
              key={i}
              id={i}
              value={v}
              last={i === last}
              action={
                i === last
                  ? () => dispatch({ type: "CHOICE_ADD" })
                  : () => dispatch({ type: "CHOICE_DELETE", i })
              }
              onChange={(value) =>
                dispatch({ type: "CHOICE_UPDATE", i, value })
              }
            />
          )
      )}
    </Container>
  )
}

export default AnswersList
