import React, { useContext, useEffect, useReducer } from "react"
import styled from "styled-components"
import { CreationContext } from "../CreationStore"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-left: 2px solid #ccc;
  padding-left: 15px;
  flex: 1;
  font-size: 0.9em;
`

const SpanOptions = styled.span`
  font-size: 15px;
  font-weight: 350;
  display: flex;
  align-items: center;
  display: flex;
  margin-right: 10px;
`
const Input = styled.input`
  margin-right: 5px;
  border-radius: 4px;
  width: 35px;
  border: 1px solid #ccc;
  box-shadow: inset rgba(62, 57, 107, 0.22) 0px 2px 1px 0px;
`

const Options: React.FC<{
  type: "SET_EXPIRE_DATE" | "SET_MASKED_DATE"
}> = ({ type }) => {
  const [, dispatch] = useContext(CreationContext)
  const [state, localDispatch] = useReducer(
    (
      prevState: {
        hours: number
        minutes: number
        days: number
      },
      action: { type: "hours" | "minutes" | "days"; value: number }
    ) => {
      const newVal = {
        [action.type]: action.value,
      }
      const newState = { ...prevState, ...newVal }

      return newState
    },
    {
      hours: 1,
      minutes: 0,
      days: 0,
    }
  )

  useEffect(() => {
    const delay = state.hours * 3600 + state.minutes * 60 + state.days * 86400
    dispatch({ type, delay })
  }, [state, type, dispatch])

  const { days, hours, minutes } = state
  return (
    <Container>
      <SpanOptions>
        <SpanOptions>
          <Input
            value={days}
            type="number"
            min="0"
            onChange={(e) =>
              localDispatch({ type: "days", value: Number(e.target.value) })
            }
          />
          Days
        </SpanOptions>
        <SpanOptions>
          <Input
            value={hours}
            type="number"
            min="0"
            onChange={(e) =>
              localDispatch({ type: "hours", value: Number(e.target.value) })
            }
          />
          Hours
        </SpanOptions>
        <SpanOptions>
          <Input
            value={minutes}
            type="number"
            min="0"
            onChange={(e) =>
              localDispatch({ type: "minutes", value: Number(e.target.value) })
            }
          />
          Minutes
        </SpanOptions>
      </SpanOptions>
    </Container>
  )
}

export default Options
