import React from "react"
import styled from "styled-components"

const Container = styled.button`
  border: 1px solid var(--white);
  @media (min-width: 701px) {
    border: 1px solid var(--gray-1);
  }
  border-radius: 9px;
  padding: 6px 9px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: baseline;
  background: var(--white);
  color: var(--black);
  &.active {
    border-color: var(--primary);
  }
  .description {
    color: var(--gray-2);
    font-size: 11px;
    line-height: 0.7;
    margin-bottom: 8px;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`

const Option: React.FC<{
  title: string
  description?: string
  active?: boolean
  flex?: number
  oneLine?: boolean
  onClick?: () => void
}> = ({ title, description, flex, active, oneLine, onClick }) => {
  return (
    <Container
      onClick={onClick}
      style={{ flex, flexDirection: oneLine ? "row" : "column" }}
      className={
        (active ? "active" : "") +
        " " +
        (onClick === undefined ? "disabled" : "")
      }
    >
      <div>{title}</div>
      {description && (
        <div className="description" style={oneLine ? { marginLeft: 5 } : {}}>
          {description}
        </div>
      )}
    </Container>
  )
}

export default Option
