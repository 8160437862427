import styled from "styled-components"
import React from "react"

const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 10px 0;
  flex-direction: column;
`

const Title = styled.div`
  font-weight: 500;
  word-break: break-all;
  display: inline-block;
`

const ScoreBar = styled.div<{ size: number }>`
  width: ${({ size }) => size}%;
  background: linear-gradient(45deg, #fc054f 0%, #ff3314 100%);
  height: 1.3em;
  padding: 2px;
  border-radius: 20px;
  flex: 1;
`
const Score = styled.div`
  font-weight: 500;
  padding-left: 5px;
  color: var(--gray-2);
  display: inline-block;
`

const ResultRank: React.FC<{
  pollType: string
  name: string
  points: number
  bestPoints: number
}> = ({ points, pollType, bestPoints, name }) => {
  let size

  const isClassic = pollType === "Classic"

  size = Math.round((points * 100) / bestPoints)

  return (
    <Container>
      <div>
        <Title>{name}</Title>
        <Score>
          {points.toFixed(0)}
          {" " + (isClassic ? (points > 1 ? " votes" : " vote") : "points")}
        </Score>
      </div>
      {<ScoreBar size={size} />}
    </Container>
  )
}
export default ResultRank
