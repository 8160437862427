import dayjs from "dayjs"
import { CreationState } from "../scenes/pollCreation/CreationStore"
import { createPoll } from "../utils/apiClient"

export function createPollMutation(
  creationState: CreationState
): Promise<string> {
  const choices = creationState.choices
    .filter((ele) => ele.trim())
    .map((ele) => ({
      name: ele.trim(),
    }))

  if (!creationState.question.trim()) {
    return Promise.reject("a question is required")
  } else if (choices.length < 2) {
    return Promise.reject("at least 2 answers are required")
  } else {
    const expireDate =
      creationState.expireDate !== 0
        ? dayjs().add(creationState.expireDate, "seconds").unix()
        : 0

    const maskedDate =
      creationState.maskedDate !== 0
        ? dayjs().add(creationState.maskedDate, "seconds").unix()
        : 0

    return createPoll({
      question: creationState.question.trim(),
      choices,
      expireDate,
      maskedDate,
      security: creationState.security ? [creationState.security] : [],
      pollType: creationState.pollType,
      authorizeVotes: true,
    }).then((data: any) => data.id)
  }
}
