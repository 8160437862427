import React from "react"
import styled from "styled-components"
import { SunFill, MoonFill } from "@styled-icons/bootstrap"
import useDarkMode from "use-dark-mode"

const Container = styled.div`
  cursor: pointer;
  display: inline-block;
`

const DarkModeSwitch: React.FC = () => {
  const { toggle, value } = useDarkMode(false)
  const Icon = value ? MoonFill : SunFill
  return (
    <Container onClick={toggle}>
      <Icon size="0.9em" />
    </Container>
  )
}

export default DarkModeSwitch
