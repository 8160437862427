import { CreateBody, PublicPoll, VoteBody } from "./types"
import base64url from "base64url"
import useSWR from "swr"

const API_PATH = "https://api.livepoll.io"

async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init)
  return res.json()
}

export function usePoll(base64ID: string, polling?: boolean) {
  const hexId = base64url.toBuffer(base64ID).toString("hex")

  const { data } = useSWR<PublicPoll>(`${API_PATH}/polls/${hexId}`, fetcher, {
    revalidateOnMount: true,
    refreshInterval: polling ? 3000 : undefined,
  })

  return data
}

export function postVote(vote: VoteBody): Promise<string | null> {
  return fetch(`${API_PATH}/polls/${vote.pollId}/votes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(vote),
  })
    .then((r) => r.json())
    .then((r) => r.error)
}

export function createPoll(body: CreateBody): Promise<string> {
  return fetch(`${API_PATH}/polls/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((r) => r.json())
}
