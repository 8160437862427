import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { RouteComponentProps } from "react-router"
import styled from "styled-components"
import TitleShare from "../../components/TitleShare"
import Block from "../../components/UI/Block"
import Loading from "../../components/UI/Loading"
import { ChoiceType } from "../../store/PollType"
import Choice from "./Choice"
import ErrorMessage from "./ErrorMessage"
import PostVote from "./PostVote"
import { usePoll } from "../../utils/apiClient"
import { PublicPoll } from "../../utils/types"

const SubTitle = styled.h3`
  color: var(--gray-2);
  font-size: 1.4em;
  font-weight: 400;
`

const ContainerIn = styled.div`
  margin: 25px 0;
  display: grid;
  word-break: break-all;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);

  @media (min-width: 701px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 900px) {
    padding: 50px 30px;
  }
`

const RootVote: React.FC<{ Poll: PublicPoll; base64ID: string }> = (props) => {
  const [state, setState] = useState<{
    choices: ChoiceType[]
    error?: string
    currentRank: number
  }>({
    currentRank: 0,
    choices: props.Poll.choices.map((choice) => ({
      value: 0,
      ...choice,
    })),
  })

  const getNextRank = () => {
    let res = 0
    state.choices.every((_, index) => {
      if (state.choices.find((o) => o.value === index + 1) === undefined) {
        res = index + 1
      }
      return res === 0
    })
    return res
  }

  const changeErrorState = (value: string) => {
    setState({
      ...state,
      error: value,
    })
  }

  const changeRankChoice = (choiceParam: ChoiceType) => {
    if (props.Poll.pollType === "AverageRanking") {
      setState({
        currentRank: state.currentRank + 1,
        choices: state.choices.map((choice) =>
          choiceParam.id === choice.id
            ? Object.assign(choice, { value: getNextRank() })
            : choice
        ),
      })
    } else {
      setState({
        choices: state.choices.map((choice) =>
          choiceParam.id === choice.id
            ? Object.assign(choice, { value: 1 })
            : Object.assign(choice, { value: 0 })
        ),
        currentRank: state.currentRank,
      })
    }
  }

  const ResetRank = (choiceParam: ChoiceType) => {
    setState((state) => ({
      currentRank: state.currentRank + 1,
      choices: state.choices.map((choice) =>
        choiceParam.id === choice.id
          ? Object.assign(choice, { value: 0 })
          : choice
      ),
    }))
  }

  const title = `${props.Poll.question} - LivePoll`
  return (
    <Block>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Helmet>
      <TitleShare title={props.Poll.question} />
      {props.Poll.pollType === "AverageRanking" ? (
        <SubTitle>Order the answers by preference</SubTitle>
      ) : (
        <SubTitle>Pick one answer</SubTitle>
      )}
      <ContainerIn>
        {state.choices.map((choice) => (
          <Choice
            key={choice.id}
            choice={choice}
            displayMedal={props.Poll.pollType === "AverageRanking"}
            callback={() => changeRankChoice(choice)}
            callbackReset={() => ResetRank(choice)}
          />
        ))}
      </ContainerIn>
      <PostVote
        nbVotes={props.Poll.nbVotes}
        callback={changeErrorState}
        dataChoices={state.choices}
        pollID={props.Poll.id}
        pollID64={props.base64ID}
        pataPollType={props.Poll.pollType}
      />
      <ErrorMessage error={state.error} />
    </Block>
  )
}

const PollVote: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const poll = usePoll(props.match.params.id)
  return poll ? (
    <RootVote Poll={poll} base64ID={props.match.params.id} />
  ) : (
    <Loading />
  )
}

export default PollVote
