import React from "react"
import styled from "styled-components"
import Block from "../../components/UI/Block"
import H2 from "../../components/UI/Title"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const H2About = styled(H2)`
  padding: 5px;
  margin-bottom: 5px;
`

const TextAbout = styled.div`
  padding: 0 30px;
  font-size: 1.2em;
  margin-bottom: 10px;
`
const TextMail = styled.div`
  font-size: 1.6em;
`

const TextAmazon = styled.div`
  padding: 5px 30px;
  font-size: 0.9em;
`

const HrLP = styled.hr`
  margin: 20px 0;
`

const About: React.FC = () => {
  return (
    <Block>
      <Container>
        <H2About id="about">About LivePoll</H2About>
      </Container>
      <Container>
        <TextAbout>
          Livepoll is a website that allows you to create
          <strong> easily</strong> and <strong>quickly </strong>
          <strong> polls</strong> or surveys with{" "}
          <strong> various options </strong>
          including <strong>ranking based vote</strong>,{" "}
          <strong>ip duplication checking</strong>,
          <strong> expiration date</strong>, <strong> hiding results</strong>
        </TextAbout>
      </Container>
      <HrLP />
      <Container>
        <H2About id="contact">Contact us</H2About>
      </Container>
      <Container>
        <TextAbout>
          Do not hesitate to contact us if you have a question or comment about
          our website at :
        </TextAbout>
        <TextMail>contact@livepoll.io</TextMail>
      </Container>
      <HrLP />
      <Container>
        <H2About id="policy">Privacy policy</H2About>
      </Container>
      <TextAbout>
        This website and the data it process are hosted on Amazon Web Services
        LLC.
      </TextAbout>
      <TextAmazon>Amazon Web Services LLC</TextAmazon>
      <TextAmazon>P.O. Box 81226</TextAmazon>
      <TextAmazon>Seattle, WA 98108-1226</TextAmazon>
      <TextAmazon>USA</TextAmazon>
    </Block>
  )
}
export default About
