import React, { KeyboardEventHandler } from "react"
import styled from "styled-components"
import { Plus } from "@styled-icons/bootstrap"

const Container = styled.div`
  display: flex;
  color: #333;
  margin-bottom: 15px;
`

const Button = styled.button`
  display: flex;
  background: transparent;
  border: 0;
  cursor: pointer;
  color: #888;
  align-items: center;
  font-size: 25px;
  padding: 0 5px;
  > svg {
    transition: all 1s ease;
  }
  > i::before {
    font-size: 2em;
    color: #888;
  }
`

const Input = styled.input`
  min-width: 0;
  margin-left: 10px;
  flex: 1;
  border: 0;
  border-bottom: 2px solid var(--gray-1);
  text-align: left;
  font-size: 20px;
  font-weight: 300;
  background: transparent;
  color: var(--black);
  &:focus {
    outline: none;
    &::-webkit-input-placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    font-size: 20px;
    font-weight: 300;
    color: var(--gray-1);
  }
`

const Answer: React.FC<{
  innerRef: any
  handleEnter: KeyboardEventHandler<HTMLInputElement>
  action: () => void
  id: number
  last: boolean
  value: string
  onChange: (value: string) => void
}> = ({ innerRef, action, id, last, value, onChange, handleEnter }) => {
  return (
    <Container>
      <Button
        onClick={action}
        aria-label={last ? "add answer" : "delete answer"}
      >
        <Plus
          width="1em"
          height="1em"
          style={{ transform: last ? "" : "rotate(45deg)" }}
        />
      </Button>
      <Input
        type="text"
        value={value}
        // value={poll.choices[id]}
        placeholder={last ? "" : `Answer ${id + 1}`}
        onChange={(e) => onChange(e.target.value)}
        tabIndex={id + 2}
        ref={innerRef}
        onKeyDown={handleEnter}
      />
    </Container>
  )
}

export default Answer
