import { ArrowLeft } from "@styled-icons/fa-solid"
import dayjs from "dayjs"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"
import TitleShare from "../../components/TitleShare"
import Block from "../../components/UI/Block"
import Button from "../../components/UI/Button"
import Loading from "../../components/UI/Loading"
import { usePoll } from "../../utils/apiClient"
import { idToBase64 } from "../../utils/base64"
import { PublicPoll } from "../../utils/types"
import ResultRank from "./ResultRank"

type Subscribe = () => void

const Message = styled.div`
  color: var(--gray-2);
  font-size: 1.4em;
  font-weight: 400;
  text-align: center;
  padding: 3em 0 2em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SubTitle = styled.span`
  color: var(--gray-2);
  font-size: 1.4em;
  font-weight: 400;
`

const Explanations = styled.span`
  color: var(--gray-2);
  font-size: 0.9em;
  font-style: oblique;
  font-weight: 330;
`

const RootResults: React.FC<{ Poll: PublicPoll; subscribe: Subscribe }> = ({
  Poll,
  subscribe,
}) => {
  const { question, id } = Poll
  const title = `${question} - LivePoll`
  return (
    <Block>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Helmet>
      <TitleShare title={question} />
      <RootResultsInner Poll={Poll} subscribe={subscribe} />
      <Link
        to={`/${idToBase64(id)}/`}
        style={{
          textDecoration: "none",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button right secondary text="Vote" Icon={ArrowLeft} />
      </Link>
    </Block>
  )
}

const RootResultsInner: React.FC<{
  subscribe: () => void
  Poll: PublicPoll
}> = ({ Poll, subscribe }) => {
  useEffect(() => {
    subscribe()
  }, [subscribe])
  if (Poll.hiddenResults) {
    const { maskedDate, nbVotes } = Poll
    const date = dayjs.unix(maskedDate)
    return (
      <Message>
        <div>Results of this poll are hidden until {date.calendar()} ({date.fromNow()})</div>
        {nbVotes >= 0 ? <div className="mt-5">{nbVotes} Votes</div> : null}
      </Message>
    )
  }

  const { pollType } = Poll
  let sortedResults: {
    score: number
    choiceId: string
  }[] = Poll.results.slice().sort((a, b) => b.score - a.score)

  const maxVote = Poll.choices.length - 1
  return (
    <React.Fragment>
      {sortedResults.length > 1 && (
        <React.Fragment>
          <SubTitle>
            {Poll.nbVotes} {Poll.nbVotes > 1 ? " votes" : " vote"}{" "}
          </SubTitle>
          {pollType !== "Classic" && (
            <Explanations>
              {" - "}
              Ranking poll: for each votes points are awarded for based on rank
              ({maxVote} points for 1st, ..., 0 for last)
            </Explanations>
          )}

          <div style={{ marginTop: 10 }}>
            {sortedResults.map((current, Rank) => (
              <ResultRank
                bestPoints={sortedResults[0].score}
                key={current.choiceId}
                points={current.score}
                pollType={pollType}
                name={
                  Poll.choices.find((ele) => current.choiceId === ele.id)!.name
                }
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const PollResults: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const poll = usePoll(props.match.params.id, true)

  return poll ? <RootResults Poll={poll} subscribe={() => {}} /> : <Loading />
}

export default PollResults
