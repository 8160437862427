import React, { useState } from "react"
import styled from "styled-components"
import { Share, Clipboard, ClipboardCheck } from "@styled-icons/bootstrap"

const Container = styled.div`
  color: var(--gray-2);
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 10px;
  flex-direction: column;
  background: rgb(0 0 0 / 5%);
  svg {
    font-size: 24px;
    // margin: 0 0.6em;
  }

  padding: 5px 11px 4px;
  border-radius: 5px;
  margin-left: 5px;
`

const ShareButtons: React.FC = () => {
  const [copied, setCopied] = useState(false)
  const ClipboardIcon = copied ? ClipboardCheck : Clipboard

  if (navigator.share != null) {
    return (
      <Container
        onClick={() => {
          navigator.share({
            title: document.title,
            url: document.URL,
          })
        }}
      >
        <Share width="1em" height="1em" />
        Share
      </Container>
    )
  }

  return (
    <Container
      onClick={() => {
        navigator.clipboard
          .writeText(window.location.href)
          .then(() => setCopied(true))
      }}
    >
      <ClipboardIcon width="1em" height="1em" />
      <span>{copied ? "Copied!" : "Copy Link"}</span>
    </Container>
  )
}

export default ShareButtons
