import { CheckCircle } from "@styled-icons/fa-solid"
import { BarChartHorizontal } from "@styled-icons/remix-fill/BarChartHorizontal"
import React, { useState } from "react"
import { RouteComponentProps } from "react-router"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"
import Button from "../../components/UI/Button"
import { ChoiceType, PollType } from "../../store/PollType"
import { postVote } from "../../utils/apiClient"

const Container = styled.div`
  margin-bottom: 7px;
  text-align: center;
  display: flex;
  justify-content: center;
`

type Props = {
  pollID: string
  pataPollType: PollType
  callback: (msg: string) => void
  dataChoices: ChoiceType[]
  pollID64: string
  nbVotes: number
}

const PostVote: React.FC<Props & RouteComponentProps> = (props) => {
  const [loading, setLoading] = useState(false)

  const isValid = () => {
    if (
      props.pataPollType === "AverageRanking" &&
      props.dataChoices.some((current) => current.value === 0)
    ) {
      props.callback("You need to vote for every options")
      return false
    }

    if (
      props.pataPollType === "Classic" &&
      !props.dataChoices.some((current) => current.value === 1)
    ) {
      props.callback("You need to vote for one option")
      return false
    }

    return true
  }

  const sendVote = async () => {
    const valid = isValid()
    if (!valid) return
    setLoading(true)

    const choice = props.dataChoices.map(({ value, id: choiceId }) => ({
      value,
      choiceId,
    }))

    const error = await postVote({ pollId: props.pollID, choice })
    if (error) {
      setLoading(false)
      props.callback(error)
    } else {
      props.history.push(`/${props.pollID64}/results`)
    }
  }

  return (
    <Container>
      <Link
        to={`/${props.pollID64}/results`}
        // secondary="true"
        style={{ textDecoration: "none" }}
      >
        <Button
          secondary
          text="Results"
          Icon={BarChartHorizontal}
          subText={
            props.nbVotes >= 0
              ? `${props.nbVotes} vote${props.nbVotes > 1 ? "s" : ""}`
              : undefined
          }
        />
      </Link>
      <Button
        text="Vote"
        Icon={CheckCircle}
        onClick={() => sendVote()}
        loading={loading}
      />
    </Container>
  )
}

export default withRouter(PostVote)
