import React, { useContext } from "react"
import styled from "styled-components"
import { CreationContext } from "../CreationStore"

const Input = styled.input`
  text-align: center;
  padding: 5px;
  border: 0;

  &:focus {
    outline: none;

    &::-webkit-input-placeholder {
      color: transparent;
    }
  }

  font-size: 1.8em;
  font-weight: 400;
  width: 100%;
  max-width: 550px;
  background: transparent;
  color: var(--black);

  &::placeholder {
    font-weight: 400;
    color: var(--black);
    opacity: 0.8;
  }

  border-bottom: 3px solid var(--primary);
`

const Container = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const Question: React.FC<{ firstAnswer: HTMLInputElement | null }> = ({
  firstAnswer,
}) => {
  const [store, dispatch] = useContext(CreationContext)
  return (
    <Container>
      <Input
        value={store.question}
        type="text"
        maxLength={70}
        placeholder="Type your question here"
        onChange={(e) =>
          dispatch({ type: "SET_QUESTION", question: e.target.value })
        }
        tabIndex={1}
        autoComplete="off"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            firstAnswer?.focus()
            event.preventDefault()
          }
        }}
      />
    </Container>
  )
}

export default Question
