import { HourglassStart, Lock, VoteYea, EyeSlash } from "@styled-icons/fa-solid"
import dayjs from "dayjs"
import React, { useContext } from "react"
import styled from "styled-components"
import { CreationContext } from "../CreationStore"
import Option from "./Option"
import TimePicker from "./TimePicker"

const Container = styled.div`
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  @media (min-width: 700px) {
    h3 {
      display: none;
    }
  }
  h3 {
    // border-bottom: 2px solid var(--primary);
    margin: 1em 0 -0.3em;
    font-weight: 600;
    font-size: 1.7em;
  }
  .options-container {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    @media (min-width: 701px) {
      margin: 0 -10px;
      > div {
        flex: 1;
        padding: 0 15px;
      }
    }
    .horizontal-options {
      display: flex;
      button {
        margin-right: 5px;
      }
      button:last-of-type {
        margin-right: 0;
      }
    }
    h6 {
      margin: 20px 0 10px;
      display: flex;
      align-items: center;
      opacity: 0.8;
      text-align: center;
      svg {
        font-size: 0.9em;
        margin-right: 8px;
        margin-left: 2px;
      }
    }
  }
`

const Options: React.FC = () => {
  const [store, dispatch] = useContext(CreationContext)
  return (
    <Container>
      <h3>Settings</h3>
      <div className="options-container">
        <div>
          <h6>
            <VoteYea size="1.2em" /> Voting system
          </h6>
          <Option
            title="Classic"
            description="Can vote for one option"
            active={store.pollType === "Classic"}
            onClick={() =>
              dispatch({ type: "SET_VOTING_SYSTEM", votingSystem: "Classic" })
            }
          />
          <Option
            title="Ranking"
            description="Points are awarded for based on rank"
            active={store.pollType === "AverageRanking"}
            onClick={() =>
              dispatch({
                type: "SET_VOTING_SYSTEM",
                votingSystem: "AverageRanking",
              })
            }
          />
          <Option
            title="Multiple choices"
            description="Can vote for multiple options"
          />
          {/*<Option*/}
          {/*  title="Ranking - Instant-runoff voting"*/}
          {/*  description="Simulate a series of runoff"*/}
          {/*/>*/}
        </div>
        <div>
          <h6>
            <Lock size="1.2em" /> Security
          </h6>
          <div className="horizontal-options">
            <Option
              title="Disabled"
              flex={2}
              active={store.security === null}
              onClick={() =>
                dispatch({
                  type: "SET_SECURITY",
                  security: null,
                })
              }
            />
            <Option
              title="IP Check"
              description="Limit to one vote per user"
              active={store.security === "Ip"}
              flex={7}
              oneLine
              onClick={() =>
                dispatch({
                  type: "SET_SECURITY",
                  security: "Ip",
                })
              }
            />
          </div>
          <h6>
            <HourglassStart size="1.2em" /> Voting expiration
          </h6>
          <div className="horizontal-options">
            <Option
              title="Disabled"
              flex={2}
              active={store.expireDate === 0}
              onClick={() =>
                dispatch({
                  type: "SET_EXPIRE_DATE",
                  delay: 0,
                })
              }
            />
            <Option
              title="Expiration in"
              oneLine
              description={
                store.expireDate > 0
                  ? dayjs().add(store.expireDate, "seconds").calendar()
                  : ""
              }
              flex={7}
              active={store.expireDate !== 0}
              onClick={() =>
                dispatch({
                  type: "SET_EXPIRE_DATE",
                  delay: 60,
                })
              }
            />
          </div>
          {store.expireDate !== 0 && <TimePicker type="SET_EXPIRE_DATE" />}

          <h6>
            <EyeSlash size="1.2em" /> Hide results before
          </h6>
          <div className="horizontal-options">
            <Option
              title="Disabled"
              flex={2}
              active={store.maskedDate === 0}
              onClick={() =>
                dispatch({
                  type: "SET_MASKED_DATE",
                  delay: 0,
                })
              }
            />
            <Option
              title="Hide before"
              flex={7}
              oneLine
              description={
                store.expireDate > 0
                  ? dayjs().add(store.maskedDate, "seconds").calendar()
                  : ""
              }
              onClick={() =>
                dispatch({
                  type: "SET_MASKED_DATE",
                  delay: 60,
                })
              }
              active={store.maskedDate !== 0}
            />
          </div>
          {store.maskedDate !== 0 && <TimePicker type="SET_MASKED_DATE" />}
        </div>
      </div>
    </Container>
  )
}

export default Options
