import React from "react"
import ShareButtons from "./Share"
import { H2 } from "./UI/Headings"

const TitleShare: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="d-flex align-items-start">
      <H2 style={{ flex: 1 }}>{title}</H2>
      <ShareButtons />
    </div>
  )
}

export default TitleShare
