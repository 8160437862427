import styled from "styled-components"
import React from "react"
import Medal from "../../components/UI/Medal"

const BlockChoiceStyled = styled.button<{ ranked: boolean }>`
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 300;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 2px solid #ffffff;
  background-color: ${(p) => (p.ranked ? "var(--primary)" : "#666")};
  font-weight: 400;
  margin: 0 10px;
  padding: 10px;
  position: relative;
  &:hover {
    transform: translate(0, -3px);
    transition: transform 0.2s;
  }
  margin: 5px;
`

const StyledSpan = styled.span`
  word-wrap: break-word;
  width: 100%;
`

const StyledMedal = styled(Medal)`
  position: absolute;
  top: -20px;
  left: -25px;
  transform: rotate(0deg);
`

const Choice: React.FC<{
  choice: any
  callbackReset: () => void
  callback: () => void
  displayMedal: boolean
}> = (props) => {
  const ranked = props.choice.value != null && props.choice.value !== 0
  return (
    <BlockChoiceStyled
      ranked={ranked}
      onClick={ranked ? props.callbackReset : props.callback}
    >
      <StyledSpan>{props.choice.name}</StyledSpan>
      {ranked && props.choice.value !== 0 && props.displayMedal && (
        <StyledMedal rank={props.choice.value} />
      )}
    </BlockChoiceStyled>
  )
}

export default Choice
