import React, { useReducer } from "react"

export const CreationContext = React.createContext<
  [CreationState, React.Dispatch<Action>]
>(null!)

export type CreationState = {
  question: string
  choices: string[]
  security: Security
  pollType: VotingSystem
  ongoingCreation: boolean
  maskedDate: number
  expireDate: number
}

type VotingSystem = "AverageRanking" | "Classic"
type Security = "Ip" | null
type Action =
  | { type: "CHOICE_UPDATE"; i: number; value: string }
  | { type: "CHOICE_ADD" }
  | { type: "CHOICE_DELETE"; i: number }
  | { type: "SET_VOTING_SYSTEM"; votingSystem: VotingSystem }
  | { type: "SET_SECURITY"; security: Security }
  | { type: "SET_QUESTION"; question: string }
  | { type: "SET_EXPIRE_DATE"; delay: number }
  | { type: "SET_MASKED_DATE"; delay: number }

function reducer(state: CreationState, action: Action): CreationState {
  switch (action.type) {
    case "CHOICE_UPDATE":
      const choices = state.choices.map((ele, i) =>
        i === action.i ? action.value : ele
      )
      if (choices.slice(-1)[0] !== "") choices.push("")
      return { ...state, choices }
    case "CHOICE_ADD":
      return { ...state, choices: [...state.choices, ""] }
    case "CHOICE_DELETE":
      return {
        ...state,
        choices: state.choices.filter((_, i) => i !== action.i),
      }
    case "SET_VOTING_SYSTEM":
      return { ...state, pollType: action.votingSystem }
    case "SET_SECURITY":
      return { ...state, security: action.security }
    case "SET_QUESTION":
      return { ...state, question: action.question }
    case "SET_EXPIRE_DATE":
      return { ...state, expireDate: action.delay }
    case "SET_MASKED_DATE":
      return { ...state, maskedDate: action.delay }
  }
}

export const CreationStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    question: "",
    choices: ["", "", ""],
    security: "Ip",
    pollType: "Classic",
    expireDate: 0,
    maskedDate: 0,
    ongoingCreation: false,
  })

  return (
    <CreationContext.Provider value={[state, dispatch]}>
      {children}
    </CreationContext.Provider>
  )
}
