import React from "react"
import styled from "styled-components"
import Block from "./Block"
import { Spinner } from "@styled-icons/fa-solid"

const BlockM = styled(Block)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 290px;
`

const Loading: React.FC = () => {
  return (
    <BlockM>
      <Spinner width="3em" height="3em" className="spin" />
    </BlockM>
  )
}

export default Loading
